<template>
  <div class="exam-page">
    <div class="page-main bg-white">
      <a-spin
        :spinning="loading"
        tip="正在提交中，请不要离开页面或有任何操作……"
      >
        <a-breadcrumb class="page-breadcrumb">
          <a-breadcrumb-item>首页</a-breadcrumb-item>
          <a-breadcrumb-item>线上考试</a-breadcrumb-item>
          <a-breadcrumb-item>
            {{ exam?.exam_name }}
          </a-breadcrumb-item>
        </a-breadcrumb>
        <div class="exam-main">
          <div class="container" v-if="question && question[index]">
            <div class="top_bar flex-between">
              <div class="flex">
                <div class="type">
                  {{
                    question[index].question_type_id == 1
                      ? "单选题"
                      : question[index].question_type_id == 2
                      ? "多选题"
                      : "判断题"
                  }}
                </div>
                <div class="number">
                  <span>{{ index + 1 }}</span>
                  / {{ question.length }}
                </div>
              </div>
              <div class="flex">
                <div class="flex time">
                  剩余时间：
                  <a-statistic-countdown
                    v-model:value="countDownTime"
                    style="margin-right: 50px"
                    @finish="getExamTime"
                    @change="changeTime"
                  ></a-statistic-countdown>
                </div>
                <a-button type="primary" @click="handlerPushExam"
                  >交卷</a-button
                >
                <!-- <div class="exit" @click="pushExam">交卷</div> -->
              </div>
            </div>
            <a-divider></a-divider>
            <div class="box">
              <!-- 1、单选2、多选3、判断 -->
              <div class="flex question_title">
                <div>{{ index + 1 }}、</div>
                <div v-html="question[index].question_title"></div>
              </div>
              <div>
                <!-- 单选、判断 -->
                <div
                  v-if="
                    question[index].question_type_id == 1 ||
                    question[index].question_type_id == 3
                  "
                  class="option_wrap"
                >
                  <template v-for="item in [1, 2, 3, 4, 5, 6]" :key="item">
                    <div
                      v-if="question[index][`question_option_${item}`]"
                      :class="
                        user_answer[index]
                          ? user_answer[index] == option[item - 1]
                            ? 'option active'
                            : 'option'
                          : 'option'
                      "
                      @click="chooseOption(item)"
                    >
                      {{ question[index][`question_option_${item}`] }}
                    </div>
                  </template>
                </div>
                <!-- 多选 -->
                <div
                  v-if="question[index].question_type_id == 2"
                  class="option_wrap"
                >
                  <template v-for="item in [1, 2, 3, 4, 5, 6]" :key="item">
                    <div
                      v-if="question[index][`question_option_${item}`]"
                      :class="
                        isExsit(option[item - 1], temp)
                          ? 'active option'
                          : isExsit(option[item - 1], toArr())
                          ? 'active option'
                          : 'option'
                      "
                      @click="chooseOption2(item)"
                    >
                      {{ question[index][`question_option_${item}`] }}
                    </div>
                  </template>
                </div>
                <!-- <div v-if="question[index].question_type_id==2" class="choose" @click="chooseMutiply">确认答案</div> -->
              </div>
            </div>
            <div
              v-if="index < question.length - 1"
              class="fix_bar flex-between"
            >
              <a-button type="defaut" class="btn" @click="goLast"
                >上一题</a-button
              >
              <a-button type="primary" class="btn" @click="goNext"
                >下一题</a-button
              >
            </div>
            <div v-else class="fix_bar flex-between">
              <a-button type="defaut" class="btn" @click="goLast"
                >上一题</a-button
              >
              <a-button type="primary" class="btn" @click="handlerPushExam"
                >交卷</a-button
              >
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { message, Modal } from "ant-design-vue";
import url from "@/utils/url.js";
import { getExamQuestionList, updateExamRecordData } from "@/api/main.js";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ExamPage",
  setup() {
    const route = useRoute();
    const pageData = reactive({
      record_id: null,
      question: null,
      exam: null,
      index: 0,
      current_question: null,
      user_answer: [],
      option: ["A", "B", "C", "D", "E", "F"],
      temp: [],
      exam_question_record: [],
      exam_question_score: 0,
      exam_duration: null,
      countDownTime: null,
      record_exam_duration: 0,
      loading: false,
      finishExam: false,
    });

    if (route.query?.recordId) {
      pageData.record_id = route.query?.recordId;
      getExamQuestionList({ record_id: route.query?.recordId })
        .then((res) => {
          if (res.code == 200) {
            pageData.question = res.data.question;
            pageData.exam = res.data.exam;
            pageData.countDownTime =
              Date.now() + Number(res.data.exam.exam_duration) * 1000;
            pageData.exam_duration = res.data.exam.exam_duration;
            pageData.exam_question_score = res.data.exam.exam_question_score;
          } else {
            // message.error(res.msg);
            Modal.error({
              title: res?.msg || "网络有误，请稍后再试(C10001)",
              onOk() {
                window.history.back();
              },
            });
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试(C10002)");
        });
    } else {
      pageData.question = null;
      pageData.exam = null;
      pageData.countDownTime = null;
      pageData.exam_duration = null;
      pageData.exam_question_score = 0;
      message.error("暂无考试");
    }

    const chooseOption = (item) => {
      let option = ["A", "B", "C", "D", "E", "F"];
      pageData.user_answer[pageData.index] = option[item - 1];
      if (pageData.user_answer[pageData.index]) {
        pageData.user_answer.splice(pageData.index, 1, option[item - 1]);
      } else {
        pageData.user_answer[pageData.index].push(option[item - 1]);
      }
    };
    const isExsit = (option, temp) => {
      if (temp.indexOf(option) != -1) {
        return true;
      } else {
        return false;
      }
    };
    const chooseMutiply = () => {
      let tempStr = pageData.temp.join(",");
      pageData.user_answer.push(tempStr);
    };
    const toArr = () => {
      if (pageData.user_answer[pageData.index]) {
        return pageData.user_answer[pageData.index].split(",");
      } else {
        return [];
      }
    };
    const chooseOption2 = (item) => {
      let option = ["A", "B", "C", "D", "E", "F"];
      if (pageData.temp.indexOf(option[item - 1]) != -1) {
        let index = pageData.temp.indexOf(option[item - 1]);
        pageData.temp.splice(index, 1);
      } else {
        pageData.temp.push(option[item - 1]);
      }
      let tempStr = pageData.temp.sort().join(",");
      let arr = toArr(pageData.user_answer[pageData.index]);
      if (arr.length >= 0) {
        pageData.user_answer.splice(pageData.index, 1, tempStr);
      } else {
        pageData.user_answer.push(tempStr);
      }
    };

    const goNext = () => {
      if (pageData.index == pageData.question.length - 1) {
        message.error("已经是最后一题！");
      } else {
        if (!pageData.user_answer[pageData.index]) {
          message.error("请确认本题答案！");

          return;
        }
        pageData.index++;
        pageData.temp = toArr(pageData.user_answer[pageData.index]);
      }
    };
    const goLast = () => {
      if (pageData.index == 0) {
        message.error("已经是第一题！");
      } else {
        pageData.index--;
      }
      pageData.temp = toArr(pageData.user_answer[pageData.index]);
      console.log("pageData.user_answer", pageData.user_answer);
    };
    const pushExam = () => {
      pageData.loading = true;
      let record = pageData.question.map((v, i) => {
        return {
          question_id: v.question_id,
          question_record_answer: pageData.user_answer[i] || "",
          question_record_score:
            v.question_type_id == 2
              ? pageData.user_answer[i] ==
                v.question_option_right.split(",").sort().join(",")
                ? pageData.exam_question_score
                : 0
              : pageData.user_answer[i] == v.question_option_right
              ? pageData.exam_question_score
              : 0,
        };
      });
      let score = 0;
      record.forEach((v) => {
        console.log(v.question_record_score);
        score += parseFloat(v.question_record_score);
      });
      console.log("record", record);
      // return;
      let data = {
        record_id: pageData.record_id,
        record_exam_duration: pageData.record_exam_duration,
        record_exam_score: score,
        exam_question_record: JSON.stringify(record),
        record_status: 1,
      };
      console.log("data", data, pageData.exam_duration);
      // return false;
      updateExamRecordData(data)
        .then((res) => {
          if (res.code == 200) {
            pageData.finishExam = true;
            url.replaceTo("/exam/result", {
              recordId: pageData.record_id,
            });
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络错误，请稍后再试");
        });
    };

    const handlerPushExam = () => {
      pageData.loading = true;
      Modal.confirm({
        title: "交卷后将无法修改，您确认交卷吗？",
        onOk() {
          pushExam();
        },
        onCancel() {
          pageData.loading = false;
        },
      });
    };

    const changeTime = (e) => {
      pageData.record_exam_duration =
        pageData.exam_duration - Math.round(e / 1000);
    };
    const getExamTime = () => {
      message.error("答题时间到");
      pageData.record_exam_duration = pageData.exam_duration;
      pushExam();
    };
    const goExamIndex = () => {
      pageData.finishExam = true;
      url.replaceTo("/exam/index");
    };

    return {
      ...toRefs(pageData),
      getExamTime,
      goNext,
      chooseOption,
      chooseMutiply,
      chooseOption2,
      goLast,
      isExsit,
      pushExam,
      toArr,
      changeTime,
      goExamIndex,
      handlerPushExam,
    };
  },
  beforeRouteLeave(to, from, next) {
    let that = this;
    if (that.record_exam_duration == that.exam_duration) {
      Modal.destroyAll();
      next();
    } else if (that.finishExam) {
      Modal.destroyAll();
      next();
    } else {
      Modal.confirm({
        content: "您确定离开此页面吗？考试结果将不会上传。",
        onOk() {
          that.finishExam = true;
          url.replaceTo(to.path);
        },
      });
      next(false);
    }
  },
  mounted() {
    let that = this;
    if ("onpagehide" in window) {
      window.addEventListener("pagehide", function (e) {
        console.log("1", 1);
        e.preventDefault();
      });
    } else {
      window.addEventListener("beforeunload", function (e) {
        console.log("1", 1);
        e.preventDefault();
      });
    }
  },
  unmounted() {
    // window.removeEventListener("popstate", this.goBack, false);
  },
  methods: {
    goBack() {
      let that = this;
      // 该事件仅在浏览器后退按钮被点击时触发
      let needCofirmRouter = "/exam/exam";
      history.pushState(history.state, null, null);
      console.log("history.state2", history.state);
      if (document.URL.split("#")[1].indexOf(needCofirmRouter) > -1) {
        if (that.record_exam_duration == that.exam_duration) {
          Modal.destroyAll();
          return true;
        } else if (that.finishExam) {
          Modal.destroyAll();
          return true;
        } else {
          Modal.confirm({
            content: "您确定离开此页面吗？考试结果将不会上传。",
            onOk() {
              that.goExamIndex();
            },
            onCancel() {
              return false;
            },
          });
        }
      }
    },
  },
});
</script>

<style lang="less" scoped>
.exam-page {
  height: 100%;
}
.exam-main {
  width: 600px;
  margin: 0 auto;
  padding: 30px 0 60px;
}
.plr-20 {
  padding: 0 20px;
}

.top_bar {
  //   height: 110px;
  padding: 0 20px;
  font-size: 14px;
  align-items: center;

  .type {
    color: #3c9af4;
    margin-right: 20px;
    position: relative;

    &::after {
      content: "";
      width: 44px;
      height: 7px;
      background-color: #2a68e6;
      border-radius: 3px;
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }

  .number {
    color: #969696;
    flex: 0 0 auto;
    text {
      color: #333333;
    }
  }

  .exit {
    width: 120px;
    height: 50px;
    background-color: #eef7ff;
    border-radius: 10px;
    color: #1e83e5;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.box {
  padding: 8px 20px;

  .question_title {
    color: #333333;
    font-size: 16px;
    margin-bottom: 60px;
    align-items: unset;
  }
}

.option_wrap {
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  .option {
    width: 48%;
    // height: 100px;
    // line-height: 100px;
    background-color: #eeeeee;
    border-radius: 50px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: #333333;
    font-size: 16px;
    padding: 12px 20px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .active {
    background-color: #1e83e5;
    color: #ffffff;
  }

  .wrong {
    background-color: #bb2f2f;
    color: #ffffff;
  }

  .wait {
    background-color: #dddddd;
  }
}

.fix_bar {
  //   position: fixed;
  //   padding: 10px 2;
  width: 100%;
  //   left: 0;
  //   bottom: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  justify-content: center;
  .btn {
    width: 200px;
  }
  .btn:first-child {
    margin-right: 30px;
  }
  .q_btn {
    width: 311px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
  }

  .last {
    background-color: #eeeeee;
    color: #969696;
  }

  .next {
    background-color: #1e83e5;
    color: #ffffff;
  }
}

.choose {
  width: 600px;
  height: 100px;
  line-height: 100px;
  background-color: #1e83e5;
  border-radius: 50px;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
}

.time {
  color: #969696;
  font-size: 12px;
  align-items: center;
  margin-right: 20px;
}

.exam-time-box {
  display: flex;
  align-items: center;
}
</style>
